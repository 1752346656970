@import "~react-image-gallery/styles/scss/image-gallery.scss";

* {
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

body {
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    padding: 0;
    margin: 0;
    font-size: 16px;
    background-color: #fafafa;
    scroll-behavior: smooth;
}

a {
    color: inherit;
    text-decoration: none;
}

.hero {
    padding: 20px 20px 80px;
    background-color: black;
    color: white;
    text-align: center;
    position: relative;

    &__image {
        &--logo {
            display: inline-block;
            height: 36px;
            margin: 16px 0 0;
        }
    }

    &__title {
        font-weight: 530;
        font-size: 2.7rem;
        text-align: center;
        margin: 48px 0 20px;
        padding: 0;

        span {
            color: #ffb401;

            sup {
                font-weight: 400;
                font-size: 2rem;
            }
        }
    }

    &__desc {
        text-align: center;
        font-size: 1rem;
        color: rgba(white, 0.65);
        max-width: 75ch;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 50px;
        line-height: 1.35;
    }
}

.form {
    padding: 30px 20px 30px;
    background-color: white;
    // border: 1px solid #eee;
    border-top: 2px solid #ffb401;
    box-shadow: 0 4px 24px rgba(black, 0.03);
    border-radius: 8px;
    text-align: left;
    position: absolute;
    top: 93%;
    left: 20px;
    right: 20px;
    z-index: 100;

    label {
        display: block;
        font-weight: 500;
        font-size: 0.8rem;
        color: #555;
        margin-bottom: 4px;
    }

    input,
    select,
    button {
        display: block;
        width: 100%;
        padding: 14px 8px;
        border: 1px solid #ddd;
        border-radius: 4px;
        font-size: 1rem;
        font-family: "Inter", sans-serif;
        margin-bottom: 12px;

        &:hover {
            cursor: pointer;
        }
    }

    button {
        border: none;
        background-color: #ffb401;
        font-weight: 600;
        color: black;
        margin-top: 20px;

        &:hover {
            cursor: pointer;
        }
    }
}
.pills {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;

    .pill {
        display: inline-flex;
        padding: 8px 16px;
        border-radius: 200px;
        background: rgba(white, 0.2);
        color: white;
        font-size: 0.8rem;

        &.disabled {
            background: rgba(white, 0.08);
            color: rgba(white, 0.75);
            border: 1px solid transparent;
        }

        img {
            height: 16px;
            display: block;
            margin-right: 4px;
        }
    }
}

.signature {
    width: 270px;
    margin-bottom: 60px;
}

.results {
    padding: 0;
    margin: 0;
    color: black;
    text-align: center;
    // margin-top: 120px;
}

.services {
    max-width: 1300px;
    margin-left: 20px;
    margin-right: 20px;
    // margin-top: 160px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    &__heading {
        margin: 470px 0 40px;
        padding: 0;
        text-align: center;
        font-size: 1.65rem;
        font-weight: 500;
    }

    &__card {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 30px 20px;
        border-radius: 8px;
        background-color: white;
        box-shadow: 0 4px 24px rgba(black, 0.03);
        text-align: center;

        &--icon {
            height: 56px;
            width: auto;
        }

        h4 {
            margin: 20px 0 0 0;
            padding: 0;
            font-size: 1.2rem;
            font-weight: 550;
        }

        p {
            line-height: 1.35;
            color: #111;
        }
    }
}

.villas__heading {
    margin: 80px 0 14px;
    padding: 0;
    text-align: center;
    font-size: 1.65rem;
    font-weight: 500;
}

.card-grid {
    margin-top: 12px;
    max-width: 100vw;
    overflow: hidden;
    padding: 20px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 24px;
}

.villa-card {
    width: 100%;
    // border: 1px solid #ccc;
    background-color: white;
    box-shadow: 0 4px 24px rgba(black, 0.03);
    border-radius: 6px;
    overflow: hidden;

    &__image {
        position: relative;

        &--primary {
            width: 100%;
            height: 240px;
            object-fit: cover;
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        gap: 8px;

        &--right {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 3px;
        }
    }

    &__rating {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: 3px;
        font-weight: 500;
        font-size: 1rem;
        padding: 12px 12px;
        border-radius: 6px;
        background-color: #f7f7f7;
    }

    &__review {
        font-size: 0.7rem;
        font-weight: 400;
        color: #777;
        white-space: nowrap;
    }

    &__content {
        padding: 16px 20px;

        &--title {
            padding: 0;
            margin: 0;
            font-weight: 600;
            line-height: 1.3;
            font-size: 1.2rem;
        }

        &--subtitle {
            padding: 0;
            margin: 4px 0 0 0;
            font-weight: 350;
            line-height: 1.3;
            font-size: 0.85rem;
            color: #555;
        }

        &--vendor {
            padding: 0;
            margin: 6px 0 0 0;
            font-weight: 350;
            line-height: 1.3;
            font-size: 0.8rem;

            a {
                font-weight: 400 !important;
                position: relative;

                &::after {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 1px;
                    left: 0;
                    bottom: -1px;
                    background-color: #ddd;
                }
            }
        }
    }

    &__capacity {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 6px;
        margin-top: 20px;

        span {
            color: #ddd;
        }

        &--item {
            font-size: 1.1rem;
            color: #555;

            span {
                font-weight: 600;
                color: black;
            }
        }
    }

    &__amenities {
        margin-top: 16px;
        margin-bottom: 0px;
        display: flex;
        width: 100%;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 8px 10px;
        border-top: 1px solid #eee;
        padding: 14px 0 0;

        &--item {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            font-size: 0.9rem;

            img {
                height: 18px;
                margin-right: 2px;
            }

            &:last-of-type {
                font-weight: 600;
            }
        }
    }

    &__cockpit {
        margin-top: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid #eee;
        padding-top: 16px;
    }

    &__price {
        &--cut {
            font-weight: 300;
            color: #555;
            margin: 0;
            padding: 0;
            font-size: 1rem;
            text-decoration: line-through;
            text-decoration-thickness: 1.2px;
        }

        &--final {
            font-weight: 600;
            margin: 4px 0 0 0;
            padding: 0;
            font-size: 1.25rem;

            span {
                font-size: 1rem;
                font-weight: 400;
                color: #555;
            }
        }
    }

    &__cta {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 6px;

        * {
            // width: 54px;
            // height: 54px;
            padding: 4px 12px 4px 6px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
        }

        &--view {
            background-color: black;
            padding: 18px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            width: 60px;
            height: 60px;
            position: absolute;
            bottom: 20px;
            right: 16px;
            border: 1px solid #333;

            img {
                width: 36px;
                height: 36px;
            }
        }

        &--whatsapp {
            background-color: #38bb47;
            // padding: 12px !important;
            color: white;
            font-weight: 600;
            font-size: 1rem;

            &:hover {
                cursor: pointer;
            }

            img {
                width: 36px;
                height: 36px;
                margin-right: -4px;
                transform: rotate(0deg) scale(1);
            }
        }
    }
}

.jiggle-animation {
    animation: jiggle 1.2s ease-in-out infinite;
}

/* Jiggle keyframes */
@keyframes jiggle {
    0%,
    100% {
        transform: rotate(0deg) scale(1);
    }
    25% {
        transform: rotate(-1.5deg) scale(1.03);
    }
    50% {
        transform: rotate(1.5deg) scale(1.03);
    }
    75% {
        transform: rotate(-1.5deg) scale(1);
    }
}

.pagination {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    margin-top: 16px;
    margin-bottom: 48px;

    li {
        a {
            min-width: 40px;
            padding: 12px;
            border: 1px solid #eee;
            background-color: white;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &:hover {
            cursor: pointer;
        }

        &.disabled a {
            color: #ccc;
        }

        &.selected a {
            background-color: black;
            color: white;
        }
    }
}

@media only screen and (max-width: 380px) {
    body {
        font-family: "Inter", sans-serif;
        font-optical-sizing: auto;
        padding: 0;
        margin: 0;
        font-size: 14px;
        background-color: #fafafa;
        scroll-behavior: smooth;
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    .hero {
        padding: 20px 20px 80px;
        background-color: black;
        color: white;
        text-align: center;
        position: relative;

        &__image {
            &--logo {
                display: inline-block;
                height: 32px;
                margin: 12px 0 0;
            }
        }

        &__title {
            font-weight: 530;
            font-size: 2.2rem;
            text-align: center;
            margin: 48px 0 20px;
            padding: 0;

            span {
                color: #ffb401;

                sup {
                    font-weight: 400;
                    font-size: 1.5rem;
                }
            }
        }

        &__desc {
            text-align: center;
            font-size: 1rem;
            color: rgba(white, 0.65);
            max-width: 75ch;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 50px;
            line-height: 1.35;
        }
    }

    .form {
        padding: 30px 20px 30px;
        background-color: white;
        // border: 1px solid #eee;
        border-top: 2px solid #ffb401;
        box-shadow: 0 4px 24px rgba(black, 0.03);
        border-radius: 8px;
        text-align: left;
        position: absolute;
        top: 92%;
        left: 20px;
        right: 20px;
        z-index: 100;

        label {
            display: block;
            font-weight: 500;
            font-size: 0.8rem;
            color: #555;
            margin-bottom: 8px;
        }

        input,
        select,
        button {
            display: block;
            width: 100%;
            padding: 10px 8px;
            border: 1px solid #ddd;
            border-radius: 4px;
            font-size: 0.9rem;
            font-family: "Inter", sans-serif;
            margin-bottom: 12px;
        }

        button {
            border: none;
            background-color: #ffb401;
            font-weight: 600;
            color: black;
            margin-top: 16px;
            padding: 12px 8px;
        }
    }

    .pills {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 6px;

        .pill {
            display: inline-flex;
            padding: 8px 16px;
            border-radius: 200px;
            background: rgba(white, 0.2);
            color: white;
            font-size: 0.7rem;

            &.disabled {
                background: rgba(white, 0.08);
                color: rgba(white, 0.75);
                border: 1px solid transparent;
            }

            img {
                height: 16px;
                display: block;
                margin-right: 4px;
            }
        }
    }

    .signature {
        width: 270px;
        margin-bottom: 60px;
    }

    .services {
        max-width: 1300px;
        margin-left: 20px;
        margin-right: 20px;
        // margin-top: 160px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        &__heading {
            margin: 420px 0 40px;
            padding: 0;
            text-align: center;
            font-size: 1.65rem;
            font-weight: 500;
        }

        &__card {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 30px 20px;
            border-radius: 8px;
            background-color: white;
            box-shadow: 0 4px 24px rgba(black, 0.03);
            text-align: center;

            &--icon {
                height: 56px;
                width: auto;
            }

            h4 {
                margin: 20px 0 0 0;
                padding: 0;
                font-size: 1.2rem;
                font-weight: 550;
            }

            p {
                line-height: 1.35;
                color: #111;
            }
        }
    }

    .villas__heading {
        margin: 80px 0 14px;
        padding: 0;
        text-align: center;
        font-size: 1.65rem;
        font-weight: 500;
    }

    .results {
        padding: 0;
        margin: 0;
        color: black;
        text-align: center;
        // margin-top: 120px;
    }

    .card-grid {
        margin-top: 12px;
        max-width: 100vw;
        overflow: hidden;
        padding: 20px;
        display: grid;
        grid-template-columns: 1fr;
        gap: 24px;
    }

    .villa-card {
        width: 100%;
        background-color: white;
        box-shadow: 0 4px 24px rgba(black, 0.03);
        border-radius: 6px;
        overflow: hidden;

        &__image {
            position: relative;

            &--primary {
                width: 100%;
                height: 240px;
                object-fit: cover;
            }
        }

        &__header {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            gap: 8px;

            &--right {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 3px;
            }
        }

        &__rating {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            gap: 3px;
            font-weight: 500;
            font-size: 0.9rem;
            padding: 12px 12px;
            border-radius: 6px;
            background-color: #f7f7f7;
        }

        &__review {
            font-size: 0.7rem;
            font-weight: 400;
            color: #777;
            white-space: nowrap;
        }

        &__content {
            padding: 16px 20px;

            &--title {
                padding: 0;
                margin: 0;
                font-weight: 600;
                line-height: 1.3;
                font-size: 1.1rem;
            }

            &--subtitle {
                padding: 0;
                margin: 4px 0 0 0;
                font-weight: 350;
                line-height: 1.3;
                font-size: 0.86rem;
                color: #555;
            }

            &--vendor {
                padding: 0;
                margin: 6px 0 0 0;
                font-weight: 350;
                line-height: 1.3;
                font-size: 0.6rem;

                a {
                    font-weight: 400 !important;
                    position: relative;

                    &::after {
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 1px;
                        left: 0;
                        bottom: -1px;
                        background-color: #ddd;
                    }
                }
            }
        }

        &__capacity {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 6px;
            margin-top: 20px;

            span {
                color: #ddd;
            }

            &--item {
                font-size: 1rem;
                color: #555;

                span {
                    font-weight: 600;
                    color: black;
                }
            }
        }

        &__amenities {
            margin-top: 16px;
            margin-bottom: 0px;
            display: flex;
            width: 100%;
            justify-content: flex-start;
            align-items: flex-start;
            flex-wrap: wrap;
            gap: 8px 10px;
            border-top: 1px solid #eee;
            padding: 14px 0 0;

            &--item {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                font-size: 0.8rem;

                img {
                    height: 16px;
                    margin-right: 3px;
                }

                &:last-of-type {
                    font-weight: 600;
                }
            }
        }

        &__cockpit {
            margin-top: 16px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-top: 1px solid #eee;
            padding-top: 16px;
        }

        &__price {
            &--cut {
                font-weight: 300;
                color: #555;
                margin: 0;
                padding: 0;
                font-size: 0.9rem;
                text-decoration: line-through;
                text-decoration-thickness: 1.2px;
            }

            &--final {
                font-weight: 600;
                margin: 4px 0 0 0;
                padding: 0;
                font-size: 1.1rem;

                span {
                    font-size: 1rem;
                    font-weight: 400;
                    color: #555;
                }
            }
        }

        &__cta {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 6px;

            * {
                // width: 54px;
                // height: 54px;
                padding: 4px 12px 4px 6px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 8px;
            }

            &--view {
                background-color: black;
                padding: 18px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 8px;
                width: 54px;
                height: 54px;
                position: absolute;
                bottom: 20px;
                right: 16px;
                border: 1px solid #333;

                img {
                    width: 30px;
                    height: 30px;
                }
            }

            &--whatsapp {
                background-color: #38bb47;
                // padding: 12px !important;
                color: white;
                font-weight: 600;
                font-size: 0.86rem;

                img {
                    width: 32px;
                    height: 32px;
                    margin-right: -8px;
                    transform: rotate(0deg) scale(1);
                }
            }
        }
    }

    .pagination {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;
        margin-top: 16px;
        margin-bottom: 48px;
        font-size: 0.8rem;

        li {
            a {
                min-width: 30px;
                padding: 12px;
                border: 1px solid #eee;
                background-color: white;
                border-radius: 4px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            &.disabled a {
                color: #ccc;
            }

            &.selected a {
                background-color: black;
                color: white;
            }
        }
    }
}

@media only screen and (min-width: 1000px) {
    body {
        font-family: "Inter", sans-serif;
        font-optical-sizing: auto;
        padding: 0;
        margin: 0;
        font-size: 14px;
        background-color: #fafafa;
        scroll-behavior: smooth;
    }

    a {
        color: inherit;
        text-decoration: none;
    }

    .hero {
        padding: 20px 20px 80px;
        background-color: black;
        color: white;
        text-align: center;
        position: relative;

        &__image {
            &--logo {
                display: inline-block;
                height: 32px;
                margin: 12px 0 0;
            }
        }

        &__title {
            font-weight: 530;
            font-size: 2.7rem;
            text-align: center;
            margin: 48px 0 20px;
            padding: 0;

            span {
                color: #ffb401;

                sup {
                    font-weight: 400;
                    font-size: 2rem;
                }
            }
        }

        &__desc {
            text-align: center;
            font-size: 1rem;
            color: rgba(white, 0.75);
            max-width: 75ch;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 50px;
            line-height: 1.35;
        }
    }

    .form {
        padding: 30px 40px;
        background-color: white;
        // border: 1px solid #eee;
        border-top: 2px solid #ffb401;
        box-shadow: 0 4px 24px rgba(black, 0.03);
        border-radius: 8px;
        text-align: left;
        position: absolute;
        top: 90%;
        z-index: 100;
        max-width: 1200px;
        margin: 0 auto;

        form {
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            gap: 10px;
            align-items: flex-end;
        }

        label {
            display: block;
            font-weight: 500;
            font-size: 0.8rem;
            color: #555;
            margin-bottom: 8px;
        }

        input,
        select,
        button {
            display: block;
            width: 100%;
            padding: 10px 8px;
            border: 1px solid #ddd;
            border-radius: 4px;
            font-size: 0.9rem;
            font-family: "Inter", sans-serif;
            margin-bottom: 12px;
        }

        button {
            border: none;
            background-color: #ffb401;
            font-weight: 600;
            color: black;
            margin-top: 16px;
            padding: 12px 8px;
        }
    }

    .results {
        padding: 0;
        margin: 0;
        color: black;
        text-align: center;
        // margin-top: 120px;
    }

    .card-grid {
        margin-top: 20px;
        margin-left: auto;
        margin-right: auto;
        max-width: 1300px;
        overflow: hidden;
        padding: 20px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 30px;
    }

    .villa-card {
        width: 100%;
        max-width: 400px;
        background-color: white;
        box-shadow: 0 4px 24px rgba(black, 0.03);
        border-radius: 6px;
        overflow: hidden;

        &__image {
            position: relative;

            &--primary {
                width: 100%;
                height: 240px;
                object-fit: cover;
            }
        }

        &__header {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            gap: 8px;

            // &--left {
            //     flex: 3;
            // }

            &--right {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 3px;
            }
        }

        &__rating {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            gap: 3px;
            font-weight: 500;
            font-size: 0.9rem;
            padding: 12px 12px;
            border-radius: 6px;
            background-color: #f7f7f7;
        }

        &__review {
            font-size: 0.7rem;
            font-weight: 400;
            color: #777;
            word-break: keep-all;
            white-space: nowrap;
        }

        &__content {
            padding: 16px 20px;

            &--title {
                padding: 0;
                margin: 0;
                font-weight: 600;
                line-height: 1.3;
                font-size: 1.1rem;
            }

            &--subtitle {
                padding: 0;
                margin: 4px 0 0 0;
                font-weight: 350;
                line-height: 1.3;
                font-size: 0.8rem;
                color: #555;
            }

            &--vendor {
                padding: 0;
                margin: 6px 0 0 0;
                font-weight: 350;
                line-height: 1.3;
                font-size: 0.6rem;

                a {
                    font-weight: 400 !important;
                    position: relative;

                    &::after {
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 1px;
                        left: 0;
                        bottom: -1px;
                        background-color: #ddd;
                    }
                }
            }
        }

        &__capacity {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 6px;
            margin-top: 20px;

            span {
                color: #ddd;
            }

            &--item {
                font-size: 1rem;
                color: #555;

                span {
                    font-weight: 600;
                    color: black;
                }
            }
        }

        &__amenities {
            margin-top: 16px;
            margin-bottom: 0px;
            display: flex;
            width: 100%;
            justify-content: flex-start;
            align-items: flex-start;
            flex-wrap: wrap;
            gap: 8px 10px;
            border-top: 1px solid #eee;
            padding: 14px 0 0;

            &--item {
                display: inline-flex;
                justify-content: center;
                align-items: center;
                font-size: 0.8rem;

                img {
                    height: 16px;
                    margin-right: 3px;
                }

                &:last-of-type {
                    font-weight: 600;
                }
            }
        }

        &__cockpit {
            margin-top: 16px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-top: 1px solid #eee;
            padding-top: 16px;
        }

        &__price {
            &--cut {
                font-weight: 300;
                color: #555;
                margin: 0;
                padding: 0;
                font-size: 0.9rem;
                text-decoration: line-through;
                text-decoration-thickness: 1.2px;
            }

            &--final {
                font-weight: 600;
                margin: 4px 0 0 0;
                padding: 0;
                font-size: 1.1rem;

                span {
                    font-size: 1rem;
                    font-weight: 400;
                    color: #555;
                }
            }
        }

        &__cta {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 6px;

            * {
                // width: 54px;
                // height: 54px;
                padding: 4px 12px 4px 6px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 8px;
            }

            &--view {
                background-color: black;
                padding: 18px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 8px;
                width: 54px;
                height: 54px;
                position: absolute;
                bottom: 20px;
                right: 16px;
                border: 1px solid #333;

                &:hover {
                    cursor: pointer;
                }

                img {
                    width: 30px;
                    height: 30px;
                }
            }

            &--whatsapp {
                background-color: #38bb47;
                // padding: 12px !important;
                color: white;
                font-weight: 600;
                font-size: 0.86rem;

                img {
                    width: 32px;
                    height: 32px;
                    margin-right: -8px;
                    transform: rotate(0deg) scale(1);
                }
            }
        }
    }

    .pagination {
        list-style: none;
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;
        margin-top: 16px;
        margin-bottom: 48px;
        font-size: 0.8rem;

        li {
            a {
                min-width: 30px;
                padding: 12px;
                border: 1px solid #eee;
                background-color: white;
                border-radius: 4px;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            &.disabled a {
                color: #ccc;
            }

            &.selected a {
                background-color: black;
                color: white;
            }
        }
    }

    .services {
        max-width: 1300px;
        margin-left: auto;
        margin-right: auto;
        // margin-top: 160px;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 20px;

        &__heading {
            margin: 150px 0 40px;
            padding: 0;
            text-align: center;
            font-size: 1.65rem;
            font-weight: 500;
        }

        &__card {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 30px 20px;
            border-radius: 8px;
            background-color: white;
            box-shadow: 0 4px 24px rgba(black, 0.03);
            text-align: center;

            &--icon {
                height: 56px;
                width: auto;
            }

            h4 {
                margin: 20px 0 0 0;
                padding: 0;
                font-size: 1.2rem;
                font-weight: 550;
            }

            p {
                line-height: 1.35;
                color: #111;
            }
        }
    }

    .villas__heading {
        margin: 80px 0 14px;
        padding: 0;
        text-align: center;
        font-size: 1.65rem;
        font-weight: 500;
    }
}
